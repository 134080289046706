<template>

    <div class="map box">

        <LocalSpinner :active="spinner_active"/>

        <div class="map__left">
            <div ref="map" id="map" style="width: 100%; height: 500px"></div>
        </div>
        <div class="map__right">

            <div class="map__intro">
                <h4>Se tidligere opgaver</h4><br>
                Vælg en branche og se hvor opgaverne er kommet fra de sidste 365 dage.</div>

            <div class="field">

                <label for="choose_trade">Se relevante opgaver</label>
                <VueMultiselect
                    v-if="trades"
                    v-model="active_trade"
                    :options="trades"
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Vælg branche"
                    label="name"
                    :show-labels="false"
                    track-by="name"
                />
            </div>

            <br>
            <div class="large_screen_amount">
                <label>Antal opgaver (365 dage)</label>
                <div class="map__amount">
                    <AnimatedNumber :number="trades_count" :hide_currency="true" />
                </div>
            </div>

        </div>

        

    </div>

</template>

<script>
import VueMultiselect from 'vue-multiselect'
import AnimatedNumber from './../components/AnimatedNumber.vue'
import LocalSpinner from './../components/LocalSpinner.vue'
import { MarkerClusterer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

var map;
var advancedMarkerElm;
var infoWindow;
export default {
    components: {AnimatedNumber, VueMultiselect, LocalSpinner},
    data() {
        return {
            openedMarkerID: null,
            active_trade: [],
            map_coords: [],
            trades_count: 0,
            spinner_active: 0,
            gmapKey: process.env.VUE_APP_GOOGLEMAPS_KEY,

            // Map options
            markers: [], // Store markers here
            markerCluster: null, // Store clusters here
            prevInfoWindow: null,
            center: {lat: 56.2250145, lng: 10.4217454},
            mapIcon: {
                url: require("@/assets/icons/mapmarker.svg"),
                scaledSize: {width: 10, height: 10},
                labelOrigin: {x: 5, y: -5}
            },

            clusterStyles: [
                {
                    textColor: "white",
                    url: require("@/assets/icons/mapmarker.svg"),
                    height: 35,
                    width: 35,
                    textSize: 14
                }
            ],
            
        }
    },

    watch: {

        active_trade: function (newTrade, oldTrade) {
            this.updateMap()
        }
    },

    computed: {
        trades() {
            return this.$store.getters.trades
        }
    },

    methods: {


        buildCluster(count) {
        var mapClusterDot = document.createElement("div");
        mapClusterDot.className = "mapClusterDot";
        mapClusterDot.innerText = count;
        return mapClusterDot;
      },

      buildMarker() {
        var mapDot = document.createElement("div");
        mapDot.className = "mapDot";
        return mapDot;
      },


        async updateMap() {
            
            if(this.active_trade?.id === undefined) return false;

            let id = parseInt(this.active_trade.id)
            this.spinner_active = true

            if( isNaN(id) ) return false;

            this.axios.get("https://api.3byggetilbud.dk/websites/pro/projects/heatmap?trade=" + id).then( (response) => {
                this.map_coords = response.data.coordinates
                this.trades_count = response.data.count
                this.updateMarkers();
                this.spinner_active = false
            })

            
        },

        updateMarkers() {
            // Clear existing markers
            this.markers.forEach(marker => marker.setMap(null));
            this.markers = [];
            this.markerWindows = [];
            if( this.markerCluster ) this.markerCluster.clearMarkers();

            const renderer = {
              render: ({ count, position }) =>
                new advancedMarkerElm({
                  //label: { text: String(count), color: "white", fontSize: "12px" },
                  position,
                  content: this.buildCluster(String(count)),
                  zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                }),
            };

            // Create new markers
            this.map_coords.forEach(coord => {
              let dot = this.buildMarker()
                const marker = new advancedMarkerElm({
                    position: { lat: coord.latitude || 0.0, lng: coord.longitude || 0.0 },
                    map: map,
                    title: coord.title,
                    content: dot,
                });

                marker.addListener("click", () => {

                    if (this.prevInfoWindow) {
                        this.prevInfoWindow.close(); // Close the previous infoWindow
                    }

                    this.prevInfoWindow = new google.maps.InfoWindow({
                        content: `<div ref="${(el) => (infoWindows[index] = el)}" class="map__infowindow">
                                    <span>${this.active_trade.name}</span>
                                    <p>${coord.title}</p>
                                </div>`
                    });

                    this.prevInfoWindow.open({
                        anchor: marker,
                        map,
                    });
                });

                this.markers.push(marker);
            });

            const markers = this.markers;
            this.markerCluster = new MarkerClusterer({
              map,
              markers,
              algorithm: new SuperClusterAlgorithm({minPoints: 10, radius: 100}),
              renderer
            })
        },



        loadGoogleMaps() {
          if (typeof google !== 'undefined') return;
          
          return (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
              key: this.gmapKey,
              v: "weekly",
            });
        },

        async initMap() {
          const { Map, InfoWindow } = await google.maps.importLibrary("maps");
          infoWindow = InfoWindow;
          const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
          advancedMarkerElm = AdvancedMarkerElement;

          map = await new Map(document.getElementById("map"), {
              center: this.center,
              zoom: 7,
              styles: this.mapStyle,
              disableDefaultUI: true,
                zoomControl: true,
                scaleControl: true,
                maxZoom: 14,
                minZoom: 6,
                mapTypeControl: false,
                streetViewControl: false,
              mapId: '2a58490f67a51be',
          });
          this.updateMarkers(); // Call to update markers after map is initialized
        },
    },


    async created() {
        this.$store.dispatch('get_trades')
        this.loadGoogleMaps()
        await this.initMap();
    }

}
</script>


<style lang="scss">


.mapDot, .mapClusterDot {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: blue;
}

.mapClusterDot {
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 14px;
  text-align: center;
  line-height: 0;
  width: auto;
  border: 1px solid #51518d;
}
  
  .map {

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
    position: relative;
    height: 100%;

    &__left {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .vue-map-container {
            height: 100%;
            height: 520px;
            div a,
            .gmnoprint:not([data-control-width]),
            .gm-style-cc {
                display: none!important;
            }

        }

       .cluster img {
            width: 100%;
            top: -9.5px;
        }
    }

    &__right {
        flex: 0 0 280px;
        padding-left: 2rem;
    }

    &__intro {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    &__amount {
        background: $color8;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        padding: 2rem 0;
        border-radius: $radius;
    }

    &__infowindow {
        max-width: 200px;
        padding: 9px;
        span {
            position: relative;
            color: $color8;
            padding: 4px 10px;
            border-radius: 40px;
            border: 1px solid $color8;
            display: inline-block;
            margin-bottom: 0.9rem;
            font-weight: 500;
            left: -3px;
        }
        p {
            font-weight: bold;
            margin: 0;
            line-height: 1.1;
        }
    }

    .gm-ui-hover-effect {
        top: 0px!important;
        right: 0px!important;
        img {
            width: 18px!important;
            height: 18px!important;
            margin: 4px!important;
        }
    }

  }


  

  @media( max-width: 2000px ) and ( min-width: 1499px ), ( max-width: 800px ) {
    .large_screen_amount {
        margin-bottom: 1rem;
    }
        .map {
            flex-direction: column;
            height: auto;
              &__left {
                  order: 2;
                  .vue-map-container {
                      height: 450px;
                  }
              }
              &__right {
                 flex: auto;
                 order: 1;
                 padding-left: 0rem;
              }
        }
  }
  

    @media( max-width: 599px )  {
     
        .map__left .vue-map-container {
                height: 300px;
        }    
    }


    .multiselect__tags {
        min-height: 40px;
        padding: 15px 40px 5px 8px;
        border: 1px solid #889bd6;
        font-size: 16px;
    }

    .multiselect__select {
        height: 46px;
    }

    .multiselect__placeholder {
        line-height: 1.1;
        padding-left: 5px;
    }
</style>